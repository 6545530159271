<template>
    <div class="demo-list">
        <header class="d-flex flex-row-reverse">
	        <Button @click="show('AddUser')" color="info" :rounded="false">
		        <i class="fas fa-plus"></i> {{ $t('user.add') }}
	        </Button>
        </header>
        <div class="data-table-container">
            <v-data-table
              :headers="headers"
              :items="listUser"
              disable-pagination
              hide-default-footer
              class="data-table table table-responsive table-hover"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="dataUser in items" v-bind:key="dataUser.id">
                    <td v-if="dataUser.disable_flg === messages.DISABLE_USER_FLAG_FALSE"></td>
                    <td v-if="dataUser.disable_flg === messages.DISABLE_USER_FLAG_TRUE"><input type="radio" disabled /></td>                    
                    <td>
                        <a @click="userDetail(dataUser.id)" class="text-primary">
                            {{ dataUser.user_mail }}
                        </a>
                    </td>
                    <td>{{ dataUser.user_name }}</td>
                    <td>{{ dataUser.user_name_disp }}</td>
                    <td v-if="dataUserOccupation">{{ dataUserOccupation[dataUser.user_occupation]}}</td>
                    <td v-if="dataUserRoles">
	                    <span v-for="(role, i) in dataUser.user_sys_auth_div.split(',')" v-bind:key="i" :class="{ 'role-1': role === '1'}">{{ getUserRoles(role, i) }}</span>
                    </td>
                    <td>{{ dataUser.login_last_datetime }}</td>
                    <td>{{ dataUser.login_fail_count }}</td>
                </tr>
                </tbody>
              </template>
            </v-data-table>
            <Pagination :total-page="totalPage" :current-page="currentPage" @onHandleChangePage="onHandleChangePage"/>
            <slide>
                <DetailUser v-if="component === 'DetailUser'" :isShowNavigation="randomString" @reload="reload" :id="idSelected"></DetailUser>
                <EditUser v-if="component === 'EditUser'"></EditUser>
                <AddUser v-if="component === 'AddUser'" :isShowNavigation="randomString" @addSuccess="addSuccess"></AddUser>
            </slide>
        </div>
    </div>
</template>
<script>
import Slide from "@/components/Slide";
import EditUser from "@/components/EditUser";
import AddUser from "@/components/AddUser";
import { MESSAGE } from "@/common/constants";
import { TOAST_TIME_OUT } from "@/common/constants";
import Pagination from "@/components/common/Pagination";
import Button from "@/components/common/Button";
import UserManagementService from "@/services/api/AdminRepository";
import {mapMutations} from "vuex";
import {INTERNAL_SERVER_ERROR, SUCCESS} from "@/constants";
import DetailUser from "@/components/DetailUser";

export default {
    name: "user-list",
    data() {
        return {
            headers: [
                { text: "無効", align: "start", sortable: false, value: "disable_flg" },
                { text: "メールアドレス", sortable: false, value: "user_mail" },
                { text: "ユーザ名", sortable: false, value: "user_name" },
                { text: "ユーザ表示名", sortable: false, value: "user_name_disp" },
                { text: "職種", sortable: false, value: "user_occupation" },
                { text: "システム権限区分", sortable: false, value: "user_sys_auth_div" },
                { text: "最終ログイン日時", sortable: false, value: "login_last_datetime" },
                { text: "ログイン失敗回数", sortable: false, value: "login_fail_count" },
            ],
            display: "hide",
            component: "",
            listUser: [],
            dataUserDetail: [],
            dataUserOccupation: [],
            dataUserRoles: [],
            messages: MESSAGE,
            totalPage: 1,
            currentPage: 1,
            perPage: 10,
            idSelected: null,
            randomString: ""
        }
    },
    components: {
        Slide,
        EditUser,
        AddUser,
        DetailUser,
        Pagination,
	    Button
    },
    created() {
        this.userOccupations();
        this.userRoles();
        this.getUsers();
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading"
        }),
	    getUserRoles(role, index) {
		    let rolesWithComma = "";
		    if (index === 0) {
			    rolesWithComma += this.dataUserRoles[role];
		    } else {
			    rolesWithComma += "," + this.dataUserRoles[role];
		    }
		    return rolesWithComma;
	    },
        show() {
            this.component = "AddUser";
            this.randomString = (Math.random() + 1).toString(36).substring(7);
        },
        reload(){
            this.userOccupations();
            this.userRoles();
            this.getUsers();
        },
        getUsers () {
            this.setIsLoading(true);
            UserManagementService.listUser(this.currentPage, this.perPage)
                .then((res) => {
                    window.scrollTo({
                        behavior: "smooth",
                        top: 0
                    });
                    const {
                        data,
                        status
                    } = res

                    this.setIsLoading(false);
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (status !== SUCCESS) {
	                    this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                        return;
                    }
                    if(data.data.length < 1 && this.currentPage > 1){
                        this.currentPage = parseInt(this.currentPage) - 1;
                        this.getUsers(this.currentPage, this.perPage)
                    }
                    this.listUser = data.data;
                    this.totalPage = data.last_page;
                    this.currentPage = data.current_page;
                })
        },
        userOccupations: function() {
            UserManagementService.userOccupation()
                .then((res) => {
                    this.dataUserOccupation = res.data;
                })
        },
        userRoles: function() {
            UserManagementService.userRole()
                .then((res) => {
                    this.dataUserRoles = res.data;
                })
        },
        onHandleChangePage(pageNumber) {
            this.currentPage = pageNumber;
            this.getUsers();
        },
        userDetail(id) {
            this.idSelected = id;
            this.component = "DetailUser";
            this.randomString = (Math.random() + 1).toString(36).substring(7);
        },
        addSuccess(id) {
            this.userDetail(id)
        }
    }
}
</script>
<style lang="sass" scoped>
    .demo-list
        .data-table-container
            display: block
    .role-1
        color: rgb(63, 117, 181)
        background-color: rgb(222, 234, 246)
        font-weight: 600
        padding: 3px 5px
</style>