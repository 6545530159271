<template>
    <v-navigation-drawer class="navigation" ref="drawer" app right hide-overlay :width="navigation.width" :class="{ 'sidebar-show' : showAnimation, 'sidebar-hidden' : hiddenAnimation }">
        <div class="add-user h-100 overflow-hidden">
            <div class="container-fluid" v-if="dataUserDetail" style="height: 100%;">
                <div class="row">
                    <div class="col-md-12 d-inline-flex justify-content-between">
                        <div class="button-header__left">
                            <!-- :disabled="!dataUserDetail.password_init_flag || isDisabledInitPass" -->
                            <button
                                class="btn btn-warning me-2"
                                @click="initialPassword"
                            >  {{ $t('user.init_pass') }}
                            </button>
                            <button
                                class="btn btn-lock me-2"
                                v-if="dataUserDetail.login_fail_count > loginFail"
                                @click="showDialogUserLock = true"
                            >アカウントロック解除</button>
                        </div>
                        <Button color="error" :rounded="false" @click="isShowDialogDelete = true">
	                        <v-icon>mdi-trash-can-outline</v-icon>
                            削除
                        </Button>
                    </div>
                </div>
	            <div class="row h-100 overflow-hidden">
		            <div class="col-12 h-100 overflow-y-auto overflow-x-hidden">
		                <div class="row">
		                    <div class="col-md-8">
		                        <div class="row" v-if="dataUserDetail.login_fail_count > loginFail">
		                            <div class="col-3 text-lock p-1 ml-3">
		                                {{ $t('user.text_lock') }}
		                            </div>
		                        </div>
		                        <div class="row">
		                            <div class="col-sm">
		                                <input type="checkbox" id="disable_flg" v-model="dataUserDetail.disable_flg">
		                              <label for="disable_flg" class="ml-2">{{ $t('user.disable_flg') }}</label>
		                            </div>
		                        </div>
		                        <div class="row">
		                            <div class="col-sm-8">
		                                <label for="email">{{ $t('user.user_mail') }} <span class="color-red">*</span></label>
		                              <Input id="email"
		                                     :placeholder="$t('user.user_mail')"
		                                     :rounded="false"
		                                     :value="dataUserDetail.user_mail"
		                                     :error-messages="errors.user_mail"
		                                     @onHandleChangeValue="(value) => onHandleChange('user_mail', value)"
		                              />
		                            </div>
		                        </div>
		                        <div class="row">
		                            <div class="col-sm-8">
		                                <label for="username">{{ $t('user.user_name') }}<span class="color-red pl-1">*</span></label>
		                              <Input id="user_name"
		                                     :placeholder="$t('user.user_name')"
		                                     :rounded="false"
		                                     :value="dataUserDetail.user_name"
		                                     :error-messages="errors.user_name"
		                                     @onHandleChangeValue="(value) => onHandleChange('user_name', value)"
		                                     />
		                            </div>
		                        </div>
		                        <div class="row">
		                            <div class="col-sm-8">
		                                <label for="username_disp">{{ $t('user.user_name_disp') }}<span class="color-red pl-1">*</span></label>
		                              <Input id="username_disp"
		                                     :placeholder="$t('user.user_name_disp')"
		                                     :rounded="false"
		                                     :value="dataUserDetail.user_name_disp"
		                                     :error-messages="errors.user_name_disp"
		                                     @onHandleChangeValue="(value) => onHandleChange('user_name_disp', value)"
		                                />
		                            </div>
		                        </div>
		                        <div class="row">
		                            <div class="col-sm-8">
		                                <label for="category">{{  $t('user.user_occupation')  }}</label>
		                                <select class="form-select" id="category" v-model="dataUserDetail.user_occupation">
		                                    <option v-for="(item, index) in dataUserOccupation" :value="index" :key="index">{{ item }}</option>
		                                </select>
		                            </div>
		                        </div>
		                        <div class="row">
		                            <div class="col-sm-8">
		                                <label for="category">{{ $t('user.user_sys_auth_div') }}<span v-show="!checkRoleIsAdmin" class="color-red pl-1">*</span></label><br>
		                                <div v-show="!checkRoleIsAdmin" v-for="(item, index) in dataUserRoles" :key="index" class="mt-2 pl-0 form-check form-check-inline">
		                                    <input
		                                        v-model="dataUserDetail.user_sys_auth_div"
		                                        type="checkbox"
		                                        :value="index"
		                                        :key="index"
		                                        :id="index"
		                                        @change="datachange"
		                                        :checked="dataUserDetail.user_sys_auth_div.includes(index)">
		                                    <label :for="index" class="checkbox-forcus ml-2">{{  item  }}</label>
		                                </div>
		                                <div v-show="checkRoleIsAdmin" class="form-check form-check-inline pl-0 mt-2">
		                                    <label style="background-color: #deeaf6; color: #3f75b5; font-weight: 600;" class="checkbox-forcus p-1 pr-5">システム管理者</label>
		                                </div>
		                                <div class="errors" v-if="errors.user_sys_auth_div">
		                                  <span class="color-red">{{  $t('user.user_sys_auth_div') }}{{ $t('user.required') }}</span>
		                                </div>
		                            </div>
		                        </div>
		                        <div class="row">
		                            <div class="col-sm-8">
		                                <label for="order_disp">{{ $t('user.disp_seq') }}</label>
		                                <Input id="disp_seq"
		                                       :type="'number'"
		                                       :placeholder="$t('user.disp_seq')"
		                                       :rounded="false"
		                                       :value="dataUserDetail.disp_seq"
		                                       @onHandleChangeValue="(value) => onHandleChange('disp_seq', value)"
		                                       :error-messages="errors.disp_seq"
		                                />
		                            </div>
		                        </div>
		                    </div>
		                    <div class="col-md-4">
		                        <div class="d-flex">
		                            <div style="width: 260px">登録情報 </div>
		                            <div style="width: 260px">更新情報</div>
		                        </div>
		                        <div
		                            class="container-info-datetime d-flex justify-content-between"
		                        >
		                            <Sheet width="260" height="40" class="p-2">
		                                {{ dateTimeDisplay(dataUserDetail.created_at, "YYYY-MM-DD HH:mm:ss") }}
		                            <span v-if="dataUserDetail.created_at && dataUserDetail.created_at">
		                                by
		                            </span>
		                            <span v-if="dataUserDetail.get_created_by">{{  String(dataUserDetail.get_created_by?.id).padStart(7, "0") }}</span>
		                            </Sheet>
		                            <Sheet width="260" height="40" class="p-2">
		                                {{ dateTimeDisplay(dataUserDetail.updated_at, "YYYY-MM-DD HH:mm:ss") }}
		                            <span v-if="(dataUserDetail.updated_at && dataUserDetail.updated_by)">
		                                by
		                            </span>
		                            <span v-if="dataUserDetail.get_updated_by">{{  String(dataUserDetail.get_updated_by?.id).padStart(7, "0") }}</span>
		                            </Sheet>
		                        </div>
		                        <div class="d-flex mt-5">
		                            <div style="width: 260px">最終ログイン日時</div>
		                            <div style="width: 260px">ログイン失敗回数</div>
		                        </div>
		                        <div
		                            class="container-info-datetime d-flex justify-content-between"
		                        >
		                            <Sheet width="260" height="40" class="p-2">
		                                {{ dateTimeDisplay(dataUserDetail.login_last_datetime, "YYYY-MM-DD HH:mm:ss") }}
		                            </Sheet>
		                            <Sheet width="260" height="40" class="p-2">
		                                {{ dataUserDetail.login_fail_count }}
		                            </Sheet>
		                        </div>
		                        <div class="d-flex mt-5">
		                            <div style="width: 260px">パスワード初期化日時</div>
		                            <div style="width: 260px">アカウントロック日時</div>
		                        </div>
		                        <div
		                            class="container-info-datetime d-flex justify-content-between"
		                        >
		                            <Sheet width="260" height="40" class="p-2">
		                                {{ dateTimeDisplay(dataUserDetail.password_init_datetime, "YYYY-MM-DD HH:mm:ss") }}
		                            </Sheet>
		                            <Sheet width="260" height="40" class="p-2">
		                                {{ dateTimeDisplay(dataUserDetail.account_lock_datetime, "YYYY-MM-DD HH:mm:ss") }}
		                            </Sheet>
		                        </div>
		                    </div>
		                </div>
		            </div>
	            </div>
                <div class="row">
                    <div class="col-md-12 d-inline-flex justify-content-between">
                        <Button color="default" :rounded="false" @click="hide"><v-icon>mdi-arrow-left</v-icon> {{ $t('user.close') }}</Button>
                        <Button color="info" :rounded="false" @click="showDialogEdit = true"><v-icon>mdi-database</v-icon> {{ $t('user.save') }}</Button>
                    </div>
                </div>
            </div>
            <Dialog :maxWidth="600" :isShow="isShowDialogDelete">
            <v-card>
                <v-container>
                    <v-card-title>
                        削除
                    </v-card-title>
                    <div class="m-3">
                        削除処理を実行します。よろしいですか？
                    </div>
                    <v-card-actions class=" d-flex align-content-center justify-content-between mr-2 ml-2">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="isShowDialogDelete = false"
                        >
                            キャンセル
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="deleteRecord()"
                        >
                            OK
                        </button>
                    </v-card-actions>
                </v-container>
          </v-card>
        </Dialog>
        <Dialog :maxWidth="600" :isShow="showDialogEdit">
	        <v-card>
		        <v-container>
			        <v-card-title>確認</v-card-title>
			        <div class="m-3">保存処理を実行します。よろしいですか？</div>
			        <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
				        <button type="button" class="btn btn-secondary" @click="showDialogEdit = false">キャンセル</button>
				        <button @click="save(); showDialogEdit = false" type="button" class="btn btn-primary float-right">OK</button>
			        </v-card-actions>
		        </v-container>
	        </v-card>
        </Dialog>
        <Dialog :maxWidth="600" :isShow="showDialogUserLock">
	        <v-card>
		        <v-container>
			        <v-card-title>確認</v-card-title>
			        <div class="m-3">アカウントロック解除処理を実行します。よろしいですか？</div>
			        <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
				        <button type="button" class="btn btn-secondary" @click="showDialogUserLock = false">キャンセル</button>
				        <button @click="userLock(); showDialogUserLock = false" type="button" class="btn btn-primary float-right">OK</button>
			        </v-card-actions>
		        </v-container>
	        </v-card>
        </Dialog>
        </div>
    </v-navigation-drawer>
</template>


<script>
import UserManagementService from "@/services/api/AdminRepository";
import { RepositoryFactory } from "@/services/api/RepositoryFactory";
import {INTERNAL_SERVER_ERROR, SUCCESS, SYSTEM_USER_MODEL} from "@/constants";
import { MAX_LOGIN_FAIL, ROLE_HIDE, STATUS_SUCCESS_CODE, TOAST_TIME_OUT } from "@/common/constants";
const SystemUserRepository = RepositoryFactory.get(SYSTEM_USER_MODEL);
import {mapMutations, mapGetters} from "vuex";
import moment from "moment"
import Input from "@/components/common/Input";
import { UserFormSchema } from "@/common/schemaValidation";
import Dialog from "@/components/common/Dialog";
import Sheet from "@/components/common/Sheet";
import Button from "@/components/common/Button";

export default {
    name: "DetailUser",
    props: {
        id: {
            default: 0
        },
        isShowNavigation: {
            default: "",
        },
    },
    components: {
        Input,
        Dialog,
        Sheet,
	    Button
    },
    data() {
        return {
            checkRoleIsAdmin: false,
            isShowDialogDelete: false,
            dataUserDetail: null,
            dataUserOccupation: null,
            dataUserRoles: null,
            form: {
                user_sys_auth_div: []
            },
            loginFail: MAX_LOGIN_FAIL,
            isDisabledInitPass: false,
            roleHile: ROLE_HIDE,
            errors: {
                user_name: "",
                user_name_disp: "",
                user_sys_auth_div: false,
                disp_seq: ""
            },
            navigation: {
                width: "80%",
                borderSize: 3
            },
            showAnimation: false,
            hiddenAnimation: false,
	        showDialogEdit: false,
	        showDialogUserLock: false,
        }
    },
    computed: {
        ...mapGetters({
            user: "common/getUser"
        }),
        cssWidth() {
            return {
                "widthAnimation": this.navigation.width,
            }
        }
    },
    created() {
        this.userDetail(this.id);
        this.userOccupations();
        this.userRoles();
    },
    watch: {
        id() {
            this.userDetail(this.id)
        },
        isShowNavigation() {
            this.hiddenAnimation = false;
            this.showAnimation = true;

            $(".navigation").one("animationend", () => {
                this.showAnimation = false;
            });
        }
    },
    mounted() {
        this.setBorderWidth();
        this.setEvents();
        this.showAnimation = true;
        this.hiddenAnimation = false;
        $(".navigation").one("animationend", () => {
            this.showAnimation = false;
        });
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading"
        }),
        dateTimeDisplay(data, format) {
            if (!data) return null;
            return moment(data).add(2,"hours").format(format)
        },
        hide() {
            // this.$store.commit("hideSlide");
            this.$emit("reload", "reload");
            this.hiddenAnimation = true;
        },
        show() {
            this.$store.commit("showSlide");
        },
        checkRole(role){
            let SYST_ADMIN = 1;
            let SUPPORTER = 2;
            let BACK_OFFICE = 4;
            let SYS_MAINTAIN = 8;

            const userRoles = [];
            for (const [key, value] of Object.entries(this.dataUserRoles)) {
                userRoles.push({
                    text: value,
                    value: key,
                });
            }

            switch (role) {
            case SYST_ADMIN:
                if(userRoles.filter(x => x.value == SYST_ADMIN).length > 0){
                    return true
                }
                break;
            case SUPPORTER:
                if(userRoles.filter(x => x.value == SUPPORTER).length > 0){
                    return true
                }
                break;
            case BACK_OFFICE:
                if(userRoles.filter(x => x.value == BACK_OFFICE).length > 0){
                    return true
                }
                break;
            case SYS_MAINTAIN:
                if(userRoles.filter(x => x.value == SYS_MAINTAIN).length > 0){
                    return true
                }
                break;
            }
            return false;
        },
        userDetail(id) {
            this.dataUserDetail = null;
            if (id) {
                this.setIsLoading(true);
                UserManagementService.getUserDetail(id)
                    .then((res) => {
	                    this.setIsLoading(false)
                        if (res.status != SUCCESS) {
	                        this.$toast.error(`[${res.error?.messageID}] ${res.error?.message}`);
                            return;
                        }
                        this.dataUserDetail = res.data.data;
                        this.dataUserDetail.user_sys_auth_div = this.dataUserDetail.user_sys_auth_div.split(",");

                        const checkRoleIsAdmin = this.dataUserDetail.user_sys_auth_div.filter(x => x == 1);
                        if(checkRoleIsAdmin.length > 0){
                            this.checkRoleIsAdmin = true;
                        }else{
                            this.checkRoleIsAdmin = false;
                        }
                    })
                this.show = true;
            }
        },
        userOccupations() {
            UserManagementService.userOccupation()
                .then((res) => {
                    this.dataUserOccupation = res.data;
                })
        },
        userRoles() {
            UserManagementService.userRole()
                .then((res) => {
                    this.dataUserRoles = res.data;
                    delete this.dataUserRoles[this.roleHile];
                })
        },
        async save() {
            UserFormSchema
                .validate(this.dataUserDetail, { abortEarly: false })
                .then(async () => {
                    this.errors = {};
                    const system_user = await SystemUserRepository.update(this.dataUserDetail);
                    if (system_user.status === STATUS_SUCCESS_CODE) {
                        this.$toast.success(system_user.data.message, {})
                    } else {
                        this.$toast.error(system_user.error.message, {})
                    }
                    UserManagementService.getUserDetail(this.id)
                        .then((res) => {
	                        if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                        return;
	                        }
                            this.dataUserDetail.account_lock_datetime = res.data.data.account_lock_datetime;
                        })
                })
                .catch(err => {
                    err.inner.forEach(error => {
                        this.errors[error.path] = error.message;
                    });
                });
        },
        async initialPassword() {
            this.isDisabledInitPass = true
            if (!this.dataUserDetail.account_lock_flag) {
                let data = {
                    id: this.dataUserDetail.id
                }
                const system_user = await SystemUserRepository.initialPassword(data);
	            if(system_user.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${system_user?.error.messageID}] ${system_user?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                if (system_user.status == STATUS_SUCCESS_CODE) {
                    this.$toast.success(system_user.data.message, {});

                    UserManagementService.getUserDetail(this.id)
                        .then((res) => {
                            this.dataUserDetail.password_init_datetime = res.data.data.password_init_datetime;
                        })
                }
            }
        },
        async userLock() {
            let data = {
                id: this.dataUserDetail.id,
                login_fail_count: 0,
                account_lock_flag: false
            }
            const system_user = await SystemUserRepository.actionLock(data);
            if (system_user.status == STATUS_SUCCESS_CODE) {
                this.dataUserDetail.account_lock_flag = false
                this.dataUserDetail.login_fail_count = 0
                this.$toast.success(system_user.data.message, {})
                UserManagementService.getUserDetail(this.id)
                    .then((res) => {
	                    if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                    this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                    return;
	                    }
                        this.dataUserDetail.account_lock_datetime = res.data.data.account_lock_datetime;
                    })
            }
        },
        async deleteRecord() {
            this.isShowDialogDelete = false;
            if(!this.dataUserDetail.checkDelete){
                this.$toast.error("入力エラー <br>他データから参照されているため、削除できません。", {})
            }else{
                const system_user = await SystemUserRepository.delete(this.dataUserDetail.id);
                if (system_user.status == STATUS_SUCCESS_CODE) {
                    this.$toast.success(system_user.data.message, {})
                    this.hiddenAnimation = true;
                    this.$store.commit("hideSlide");
                    this.$emit("reload", "reload")
                }
            }
        },
        datachange($e) {
            if(this.dataUserDetail.user_sys_auth_div.length) {
                this.errors["user_sys_auth_div"] = ""
            }
        },
        onHandleChange(field, value) {
            if (value === undefined || value === null) return;
            this.dataUserDetail[field] = value;
            if(value){
                this.dataUserDetail[field] = value.trim()
            }
            this.validate(field)
        },
        validate(field) {
            UserFormSchema
                .validateAt(field, this.dataUserDetail)
                .then(() => {
                    this.errors[field] = ""
                })
                .catch(err => {
                    this.errors[field] = err.message
                });
        },
        setBorderWidth() {
            let i = this.$refs.drawer.$el.querySelector(".v-navigation-drawer__border");
            i.style.width = this.navigation.borderSize + "px";
            i.style.cursor = "ew-resize";
        },
        setEvents() {
            const minSize = this.navigation.borderSize;
            const el = this.$refs.drawer.$el;
            const drawerBorder = el.querySelector(".v-navigation-drawer__border");
            const direction = el.classList.contains("v-navigation-drawer--right")
                ? "right"
                : "left";

            function resize(e) {
                document.body.style.cursor = "ew-resize";
                let f =
                    direction === "right"
                        ? document.body.scrollWidth - e.clientX
                        : e.clientX;
                el.style.width = f + "px";
            }

            drawerBorder.addEventListener(
                "mousedown",
                (e) => {
                    if (e.offsetX < minSize) {
                        el.style.transition = "initial";
                        document.addEventListener("mousemove", resize, false);
                    }
                },
                false
            );

            document.addEventListener(
                "mouseup",
                () => {
                    el.style.transition = "";
                    this.navigation.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);
                },
                false
            );
        }
    }
};
</script>
<style lang="sass" scoped>
    @import "../styles/common"
    .sidebar-show
        animation: show 0.5s ease forwards

    .sidebar-hidden
        animation: hidden 0.5s ease forwards

    @media (max-width: 1025px)
        .navigation
            transform: translate(0%) !important
        .v-navigation-drawer--close
            visibility: unset

    @keyframes hidden
        from
            width: var(widthAnimation)
        to
            width: 0

    @keyframes show
        from
            width: 0
        to
            width: var(widthAnimation)

    .btn-lock
        background-color: #ffa0f9
        color: red
        border: none
        &:hover
            background-color: #fbb1fb
            color: red
    .text-lock
        background-color: #ffa0f9
        color: red
</style>
