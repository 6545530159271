import Repository from "./Repository";
import { ADMIN_REPOSITORY } from "../../constants/";
import { GROUP_USER_OCCUPATION, GROUP_USER_ROLE } from "@/common/constants";

export default {
    async listUser(page = 1, limit = 10) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/list-user`, {page, limit});
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async userOccupation() {
        try {
            return await Repository.get(`code_define?group=${GROUP_USER_OCCUPATION}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async userRole() {
        try {
            return await Repository.get(`code_define?group=${GROUP_USER_ROLE}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getUserDetail(USER_ID) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/detail-user/${USER_ID}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status : false,
            errorCode : errorCode,
            data : e.response ? e.response.data : "error",
            error : e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
