<template>
    <v-navigation-drawer class="navigation" ref="drawer" app right hide-overlay :width="navigation.width" :class="{ 'sidebar-show' : showAnimation, 'sidebar-hidden' : hiddenAnimation }">
        <div class="add-user h-100 overflow-hidden">
            <div class="container-fluid" style="height: 100%;">
                <div class="row h-100 overflow-y-auto overflow-x-hidden">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm">
                                <input type="checkbox" id="disable_flg" v-model="data.disable_flg">
                              <label for="disable_flg" class="ml-3">{{ $t('user.disable_flg') }}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <label for="email">{{ $t('user.user_mail') }} <span class="color-red">*</span> </label>
                              <Input id="email"
                                     :placeholder="$t('user.user_mail')"
                                     :rounded="false"
                                     :value="data.user_mail"
                                     :error-messages="errors.user_mail"
                                     @onHandleChangeValue="(value) => onHandleChange('user_mail', value)"
                              />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <label for="username">{{ $t('user.user_name') }} <span class="color-red">*</span></label>
                              <Input id="user_name"
                                     :placeholder="$t('user.user_name')"
                                     :rounded="false"
                                     :value="data.user_name"
                                     :error-messages="errors.user_name"
                                     @onHandleChangeValue="(value) => onHandleChange('user_name', value)"
                              />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <label for="username_disp">{{ $t('user.user_name_disp') }} <span class="color-red">*</span></label>
                              <Input id="username_disp"
                                     :placeholder="$t('user.user_name_disp')"
                                     :rounded="false"
                                     :value="data.user_name_disp"
                                     :error-messages="errors.user_name_disp"
                                     @onHandleChangeValue="(value) => onHandleChange('user_name_disp', value)"
                              />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <label for="category">{{ $t('user.user_occupation') }}</label>
                                <select class="form-select" id="category" v-model="data.user_occupation">
                                    <option v-for="(item, index) in dataUserOccupation" :value="index" :key="index">{{ item }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <label for="category">{{ $t('user.user_sys_auth_div') }} <span class="color-red">*</span></label><br>
                                <div v-for="(item, index) in dataUserRoles" :key="index" class="form-check form-check-inline">
                                        <input
                                            v-model="data.user_sys_auth_div"
                                            type="checkbox"
                                            :value="index"
                                            :key="index"
                                            :id="index"
                                            @change="datachange"
                                            :checked="data.user_sys_auth_div.includes(index)"
                                        >
                                        <label :for="index" class="checkbox-forcus ml-2">{{  item  }}</label>
                                </div>
                                <div class="v-text-field__details ml-3" v-if="errors.user_sys_auth_div">
                                  <span class="v-messages theme--light error--text v-messages__wrapper">{{  $t('user.user_sys_auth_div') }}{{ $t('user.required') }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <label for="order_disp">{{ $t('user.disp_seq') }}</label>
                              <Input id="disp_seq"
                                     :type="'number'"
                                     :placeholder="$t('user.disp_seq')"
                                     :rounded="false"
                                     :value="data.disp_seq.toString()"
                                     :error-messages="errors.disp_seq"
                                     @onHandleChangeValue="(value) => onHandleChange('disp_seq', value)"
                              />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-4">
                        <div class="row">
                            <div class="col-sm">
                                <span class="badge bg-black">{{ $t('user.created_by') }}:</span>
                                <p></p>
                            </div>
                            <div class="col-sm">
                                <span class="badge bg-black">{{$t('user.updated_by')}}:</span>
                                <p></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <span class="badge bg-black">{{$t('user.login_last_datetime')}}:</span>
                                <p></p>
                            </div>
                            <div class="col-sm">
                                <span class="badge bg-black">{{ $t('user.login_fail_count') }}:</span>
                                <p></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm">
                                <span class="badge bg-black">{{$t('user.login_fail_count')}}:</span>
                                <p></p>
                            </div>
                            <div class="col-sm">
                                <span class="badge bg-black">{{ $t('user.login_fail_count') }}:</span>
                                <p></p>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-md-4">
                        <div class="d-flex">
                            <div style="width: 260px">登録情報</div>
                            <div style="width: 260px">更新情報</div>
                        </div>
                        <div
                            class="container-info-datetime d-flex justify-content-between"
                        >
                            <Sheet width="260" height="40" class="p-2"></Sheet>
                            <Sheet width="260" height="40" class="p-2"></Sheet>
                        </div>
                        <div class="d-flex mt-5">
                            <div style="width: 260px">最終ログイン日時</div>
                            <div style="width: 260px">ログイン失敗回数</div>
                        </div>
                        <div
                            class="container-info-datetime d-flex justify-content-between"
                        >
                            <Sheet width="260" height="40" class="p-2"></Sheet>
                            <Sheet width="260" height="40" class="p-2"></Sheet>
                        </div>
                        <div class="d-flex mt-5">
                            <div style="width: 260px">パスワード初期化日時</div>
                            <div style="width: 260px">アカウントロック日時</div>
                        </div>
                        <div
                            class="container-info-datetime d-flex justify-content-between"
                        >
                            <Sheet width="260" height="40" class="p-2"></Sheet>
                            <Sheet width="260" height="40" class="p-2"></Sheet>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 d-inline-flex justify-content-between">
	                    <Button color="default" :rounded="false" @click="hide()"><v-icon>mdi-arrow-left</v-icon> {{ $t('user.close') }}</Button>
	                    <Button color="info" :rounded="false" @click="showDialogAdd = true"><v-icon>mdi-database</v-icon> {{ $t('user.save') }}</Button>
                    </div>
                </div>
            </div>
        </div>
	    <Dialog :maxWidth="600" :isShow="showDialogAdd">
		    <v-card>
			    <v-container>
				    <v-card-title>確認</v-card-title>
				    <div class="m-3">保存処理を実行します。よろしいですか？</div>
				    <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
					    <button type="button" class="btn btn-secondary" @click="showDialogAdd = false">キャンセル</button>
					    <button @click="show(); showDialogAdd = false" type="button" class="btn btn-primary float-right">OK</button>
				    </v-card-actions>
			    </v-container>
		    </v-card>
	    </Dialog>
    </v-navigation-drawer>
</template>

<script>
import UserManagementService from "@/services/api/AdminRepository";
import { RepositoryFactory } from "@/services/api/RepositoryFactory";
import { SYSTEM_USER_MODEL } from "@/constants";
import { MAX_LOGIN_FAIL, STATUS_SUCCESS_CODE, ROLE_HIDE } from "@/common/constants";
import {UserFormSchema} from "@/common/schemaValidation";
import Input from "@/components/common/Input";
import Sheet from "@/components/common/Sheet";
import Button from "@/components/common/Button";
import Dialog from "@/components/common/Dialog";

const SystemUserRepository = RepositoryFactory.get(SYSTEM_USER_MODEL);
export default {
    name: "AddUser",
    components: {
        Input,
        Sheet,
	    Button,
	    Dialog
    },
    props: {
        isShowNavigation: {
            default: "",
        },
    },
    data() {
        return {
            data: {
                id: "",
                disable_flg: false,
                user_mail: "",
                user_name: "",
                user_name_disp: "",
                user_occupation: 20,
                user_sys_auth_div: [],
                disp_seq: 0,
                created_by: "",
                updated_by: "",
                login_last_datetime: "",
                login_fail_count: 0,
                password_init_datetime: "",
                account_lock_datetime: ""
            },
            dataUserOccupation: null,
            dataUserRoles: null,
            form: {
                user_sys_auth_div: []
            },
            loginFail: MAX_LOGIN_FAIL,
            isDisabledInitPass: false,
            roleHile: ROLE_HIDE,
            errors: {
                user_mail: "",
                user_name: "",
                user_name_disp: "",
                user_sys_auth_div: false,
                disp_seq: ""
            },
            navigation: {
                width: "80%",
                borderSize: 3
            },
            showAnimation: false,
            hiddenAnimation: false,
	        showDialogAdd: false,
        }
    },
    created() {
        this.userOccupations();
        this.userRoles();
    },
    computed: {
        cssWidth() {
            return {
                "widthAnimation": this.navigation.width,
            }
        }
    },
    watch: {
        isShowNavigation() {
            this.hiddenAnimation = false;
            this.showAnimation = true;

            $(".navigation").one("animationend", () => {
                this.showAnimation = false;
            });
        }
    },
    mounted() {
        this.setBorderWidth();
        this.setEvents();
        this.showAnimation = true;
        this.hiddenAnimation = false;
    },
    methods: {
        hide() {
            this.hiddenAnimation = true;
            this.$store.commit("hideSlide");
            this.$emit("hide", "hide")
        },
        async show() {
            UserFormSchema
                .validate(this.data, { abortEarly: false })
                .then(async () => {
                    this.data.user_sys_auth_div = this.data.user_sys_auth_div.toString();
                    this.errors = {};
                    const system_user = await SystemUserRepository.create(this.data);
                    if (system_user.status === STATUS_SUCCESS_CODE) {
                        this.$emit("addSuccess", system_user.data.id)
                        this.$toast.success("ユーザの登録が完了しました。", {});
                    } else {
                        this.$toast.error(system_user.error.message, {});
                        this.data.user_sys_auth_div = this.data.user_sys_auth_div.split(",");
                    }
                })
                .catch(err => {
                    err.inner.forEach(error => {
                        this.errors[error.path] = error.message;
                    });
                });
        },
        userOccupations: function() {
            UserManagementService.userOccupation()
                .then((res) => {
                    this.dataUserOccupation = res.data;
                })
        },
        userRoles: function() {
            UserManagementService.userRole()
                .then((res) => {
                    this.dataUserRoles = res.data;
                    delete this.dataUserRoles[this.roleHile]
                })
        },
        datachange($e) {
            if(this.data.user_sys_auth_div.length) {
                this.errors["user_sys_auth_div"] = ""
            }
        },
        onHandleChange(field, value) {
            if (value === undefined || value === null) return;
            this.data[field] = value.trim()
            this.validate(field)
        },
        validate(field) {
            UserFormSchema
                .validateAt(field, this.data)
                .then(() => {
                    this.errors[field] = ""
                })
                .catch(err => {
                    this.errors[field] = err.message
                });
        },
        setBorderWidth() {
            let i = this.$refs.drawer.$el.querySelector(".v-navigation-drawer__border");
            i.style.width = this.navigation.borderSize + "px";
            i.style.cursor = "ew-resize";
        },
        setEvents() {
            const minSize = this.navigation.borderSize;
            const el = this.$refs.drawer.$el;
            const drawerBorder = el.querySelector(".v-navigation-drawer__border");
            const direction = el.classList.contains("v-navigation-drawer--right")
                ? "right"
                : "left";

            function resize(e) {
                document.body.style.cursor = "ew-resize";
                let f =
                    direction === "right"
                        ? document.body.scrollWidth - e.clientX
                        : e.clientX;
                el.style.width = f + "px";
            }

            drawerBorder.addEventListener(
                "mousedown",
                (e) => {
                    if (e.offsetX < minSize) {
                        el.style.transition = "initial";
                        document.addEventListener("mousemove", resize, false);
                    }
                },
                false
            );

            document.addEventListener(
                "mouseup",
                () => {
                    el.style.transition = "";
                    this.navigation.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);
                },
                false
            );
        }
    }
};
</script>
<style lang="sass" scoped>
    .sidebar-show
        animation: show 0.5s ease forwards

    .sidebar-hidden
        animation: hidden 0.5s ease forwards

    @media (max-width: 1025px)
        .navigation
            transform: translate(0%) !important
        .v-navigation-drawer--close
            visibility: unset

    @keyframes hidden
        from
            width: var(widthAnimation)
        to
            width: 0

    @keyframes show
        from
            width: 0
        to
            width: var(widthAnimation)
</style>
