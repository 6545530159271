<template>
    <div class="edit-user">
        Edit user
    </div>
</template>

<script>
export default {
    name: "EditUser"
};
</script>